/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";





@media screen and (max-width: 1800px) {
  .main {
    height: 45vmin;
    width: 45vmin;
    border: 3px solid #868686;
    margin: auto;
   
    overflow: hidden;
    font-size: small;
    text-align: center;
    justify-content: center;
    position: relative;
  }
  
  .sub {
    height: 31.5vmin;
    width: 31.5vmin;
    border: 3px solid #868686;
    transform: rotate(45deg);
    margin-left: 6.5vmin;
    margin-top: 6.5vmin;
    position: absolute;
  }
  
  .line1 {
    height: 0;
    width: 72.5vmin;
    border-bottom: 3px solid #868686;
    transform: rotate(135deg);
    margin-top: 19vmin;
    margin-left: -10.5vmin;
    position: absolute;
  }
  
  .line2 {
    height: 0;
    width: 73.5vmin;
    border-top: 3px solid #868686;
    transform: rotate(-135deg);
    margin-left: -18vmin;
    margin-top: 18.5vmin;
    position: absolute;
  }
  
  p {
    
    position: absolute;
  }
  
  .p1 {
   
    text-align: center;
    min-width: 20vmin;
    max-width: 20vmin;
    margin-left: 1vmin;
    font-size:1.50vmin;
    position: absolute;
  
  }
  
  .p01 {
    margin-top: 8.5vmin;
    margin-left: 10.5vmin;
    min-width: 1vmin;
    max-width: 2.5vmin;
    font-size:1.50vmin;
    text-align: center;
    position: absolute;
  }
  
  .p2 {
  
    text-align: center;
    min-width: 20vmin;
    max-width: 20vmin;
    margin-left: 23.5vmin;
    font-size:1.50vmin;
    position: absolute;
  }
  
  .p02 {
    margin-top: 8.5vmin;
    margin-left: 33vmin;
    min-width: 1vmin;
    max-width: 2.5vmin;
    font-size:1.50vmin;
    text-align: center;
    position: absolute;
  }
  
  .p3 {
    text-align: center;
  
  word-break: break-all;
  overflow: auto;
  
  align-items: center;
  display: flex;
    min-width: 2.5vmin;
    max-width: 3.5vmin;
    max-height:20vmin;
    min-height: 20vmin;
    margin-left: 1vmin;
    font-size:1.50vmin;
    position: absolute;
  }
  
  .p03 {
    margin-top: 10.75vmin;
    margin-left: 8.5vmin;
    min-width: 1vmin;
    max-width: 2.5vmin;
    font-size:1.50vmin;
    text-align: center;
    position: absolute;
  }
  
  .p4 {
    text-align: center;
    min-width: 20vmin;
    max-width: 20vmin;
    margin-left: 12.5vmin;
    margin-top: 10.75vmin;
    font-size:1.50vmin;
    position: absolute;
    
  }
  
  .p04 {
    margin-left: 21.5vmin;
    margin-top: 19vmin;
    min-width: 1vmin;
    max-width: 2.5vmin;
    font-size:1.50vmin;
    text-align: center;
    position: absolute;
  }
  
  .p5 {
    text-align: center;
  
  word-break: break-all;
  overflow: auto;
  
  align-items: center;
  display: flex;
     min-width: 2.5vmin;
     max-width: 2.5vmin;
     max-height:20vmin;
     min-height: 20vmin;
     
     margin-left: 41vmin;
     font-size:1.50vmin;
     position: absolute;
  }
  
  .p05 {
    margin-top: 10vmin;
    margin-left: 35.5vmin;
    min-width: 1vmin;
    max-width: 2.5vmin;
    font-size:1.50vmin;
    text-align: center;
    position: absolute;
  }
  
  .p6 {
    text-align: center;
    min-width: 20vmin;
    max-width: 20vmin;
    margin-left: 1vmin;
    margin-top: 21.5vmin;
    font-size:1.50vmin;
    position: absolute;
  }
  
  .p06 {
    margin-left: 19.5vmin;
    margin-top: 21.5vmin;
    min-width: 1vmin;
    max-width: 2.5vmin;
    font-size:1.50vmin;
    text-align: center;
    position: absolute;
  }
  
  .p7 {
    text-align: center;
    min-width: 20vmin;
    max-width: 20vmin;
    margin-left: 21vmin;
    margin-top: 21.5vmin;
    font-size:1.50vmin;
    position: absolute;
  }
  
  .p07 {
    margin-left: 23.5vmin;
    margin-top: 21.5vmin;
    min-width: 1vmin;
    max-width: 2.5vmin;
    font-size:1.50vmin;
    text-align: center;
    position: absolute;
  }
  
  .p8 {
    text-align: center;
  
  word-break: break-all;
  overflow: auto;
  
  align-items: center;
  display: flex;
    min-width: 2.5vmin;
    max-width: 3.5vmin;
    max-height:20vmin;
    min-height: 20vmin;
    margin-left: 1vmin;
    margin-top:23.5vmin;
    font-size:1.50vmin;
    position: absolute;
  }
  
  .p08 {
    margin-top: 32.75vmin;
    margin-left: 8.5vmin;
    min-width: 1vmin;
    max-width: 2.5vmin;
    font-size:1.50vmin;
    text-align: center;
    position: absolute;
  }
  
  .p9 {
    text-align: center;
    min-width: 20vmin;
    max-width: 20vmin;
    margin-left: 12.5vmin;
    margin-top: 32.75vmin;
    font-size:1.50vmin;
    position: absolute;
  }
  
  .p09 {
    margin-left: 21.5vmin;
    margin-top: 23.5vmin;
    min-width: 1vmin;
    max-width: 2.5vmin;
    font-size:1.50vmin;
    text-align: center;
    position: absolute;
  }
  
  .p10 {
    text-align: center;
  
  word-break: break-all;
  overflow: auto;
  
  align-items: center;
  display: flex;
     min-width: 2.5vmin;
     max-width: 3vmin;
     max-height:20vmin;
     min-height: 20vmin;
     margin-top: 23.5vmin;
     margin-left: 41vmin;
     font-size:1.50vmin;
     position: absolute;
  }
  
  .p010 {
    margin-top: 32.75vmin;
    margin-left: 35.5vmin;
    min-width: 1vmin;
    max-width: 2.5vmin;
   
    font-size:1.50vmin;
    text-align: center;
    position: absolute;
  }
  
  .p11 {
    text-align: center;
    min-width: 20vmin;
    max-width: 20vmin;
    margin-top: 42vmin;
    margin-left: 1vmin;
    font-size:1.50vmin;
    position: absolute;
  }
  
  .p011 {
    margin-top: 35vmin;
    margin-left: 10.5vmin;
    min-width: 1vmin;
    max-width: 2.5vmin;
    font-size:1.50vmin;
    text-align: center;
    position: absolute;
  }
  
  .p12 {
    text-align: center;
    min-width: 20vmin;
    max-width: 20vmin;
    margin-top: 42vmin;
    margin-left: 23.5vmin;
    font-size:1.50vmin;
    position: absolute;
  }
  
  .p012 {
    margin-top: 35vmin;
    margin-left: 32.5vmin;
    min-width: 1vmin;
    max-width: 2.4vmin;
    font-size:1.50vmin;
    text-align: center;
    position: absolute;
  }
  }
  @media screen and (max-width: 991px) {
  .main {
    height: 90vmin;
    width: 90vmin;
    border: 3px solid #868686;
    margin: auto;
   
    overflow: hidden;
    font-size: small;
    text-align: center;
    justify-content: center;
    position: relative;
  }
  
  .sub {
    height: 63vmin;
    width: 63vmin;
    border: 3px solid #868686;
    transform: rotate(45deg);
    margin-left: 13vmin;
    margin-top: 13vmin;
    position: absolute;
  }
  
  .line1 {
    height: 0;
    width: 145vmin;
    border-bottom: 3px solid #868686;
    transform: rotate(135deg);
    margin-top: 38vmin;
    margin-left: -21vmin;
    position: absolute;
  }
  
  .line2 {
    height: 0;
    width: 147vmin;
    border-top: 3px solid #868686;
    transform: rotate(-135deg);
    margin-left: -36vmin;
    margin-top: 37vmin;
    position: absolute;
  }
  
  p {
    
    position: absolute;
  }
  
  .p1 {
   
    text-align: center;
    min-width: 40vmin;
    max-width: 40vmin;
    margin-left: 2vmin;
    font-size:3vmin;
    position: absolute;
  
  }
  
  .p01 {
    margin-top: 17vmin;
    margin-left: 21vmin;
    min-width: 2vmin;
    max-width: 5vmin;
    font-size:3vmin;
    text-align: center;
    position: absolute;
  }
  
  .p2 {
  
    text-align: center;
    min-width: 40vmin;
    max-width: 40vmin;
    margin-left: 47vmin;
    font-size:3vmin;
    position: absolute;
  }
  
  .p02 {
    margin-top: 17vmin;
    margin-left: 66vmin;
    min-width: 2vmin;
    max-width: 5vmin;
    font-size:3vmin;
    text-align: center;
    position: absolute;
  }
  
  .p3 {
    text-align: center;
  
  word-break: break-all;
  overflow: auto;
  
  align-items: center;
  display: flex;
    min-width: 5vmin;
    max-width: 6vmin;
    max-height:40vmin;
    min-height: 40vmin;
    margin-left: 2vmin;
    font-size:3vmin;
    position: absolute;
  }
  
  .p03 {
    margin-top: 21.5vmin;
    margin-left: 17vmin;
    min-width: 2vmin;
    max-width: 5vmin;
    font-size:3vmin;
    text-align: center;
    position: absolute;
  }
  
  .p4 {
    text-align: center;
    min-width: 40vmin;
    max-width: 40vmin;
    margin-left: 25vmin;
    margin-top: 21.5vmin;
    font-size:3vmin;
    position: absolute;
    
  }
  
  .p04 {
    margin-left: 43vmin;
    margin-top: 38vmin;
    min-width: 2vmin;
    max-width: 5vmin;
    font-size:3vmin;
    text-align: center;
    position: absolute;
  }
  
  .p5 {
    text-align: center;
  
  word-break: break-all;
  overflow: auto;
  
  align-items: center;
  display: flex;
     min-width: 5vmin;
     max-width: 5vmin;
     max-height:40vmin;
     min-height: 40vmin;
     
     margin-left: 84vmin;
     font-size:3vmin;
     position: absolute;
  }
  
  .p05 {
    margin-top: 20vmin;
    margin-left: 71vmin;
    min-width: 2vmin;
    max-width: 5vmin;
    font-size:3vmin;
    text-align: center;
    position: absolute;
  }
  
  .p6 {
    text-align: center;
    min-width: 40vmin;
    max-width: 40vmin;
    margin-left: 2vmin;
    margin-top: 43vmin;
    font-size:3vmin;
    position: absolute;
  }
  
  .p06 {
    margin-left: 39vmin;
    margin-top: 43vmin;
    min-width: 2vmin;
    max-width: 5vmin;
    font-size:3vmin;
    text-align: center;
    position: absolute;
  }
  
  .p7 {
    text-align: center;
    min-width: 40vmin;
    max-width: 40vmin;
    margin-left: 42vmin;
    margin-top: 43vmin;
    font-size:3vmin;
    position: absolute;
  }
  
  .p07 {
    margin-left: 47vmin;
    margin-top: 43vmin;
    min-width: 2vmin;
    max-width: 5vmin;
    font-size:3vmin;
    text-align: center;
    position: absolute;
  }
  
  .p8 {
    text-align: center;
  
  word-break: break-all;
  overflow: auto;
  
  align-items: center;
  display: flex;
    min-width: 5vmin;
    max-width: 6vmin;
    max-height:40vmin;
    min-height: 40vmin;
    margin-left: 2vmin;
    margin-top:47vmin;
    font-size:3vmin;
    position: absolute;
  }
  
  .p08 {
    margin-top: 65.5vmin;
    margin-left: 17vmin;
    min-width: 2vmin;
    max-width: 5vmin;
    font-size:3vmin;
    text-align: center;
    position: absolute;
  }
  
  .p9 {
    text-align: center;
    min-width: 40vmin;
    max-width: 40vmin;
    margin-left: 25vmin;
    margin-top: 65.5vmin;
    font-size:3vmin;
    position: absolute;
  }
  
  .p09 {
    margin-left: 43vmin;
    margin-top: 47vmin;
    min-width: 2vmin;
    max-width: 5vmin;
    font-size:3vmin;
    text-align: center;
    position: absolute;
  }
  
  .p10 {
    text-align: center;
  
  word-break: break-all;
  overflow: auto;
  
  align-items: center;
  display: flex;
     min-width: 5vmin;
     max-width: 6vmin;
     max-height:40vmin;
     min-height: 40vmin;
     margin-top: 47vmin;
     margin-left: 84vmin;
     font-size:3vmin;
     position: absolute;
  }
  
  .p010 {
    margin-top: 65.5vmin;
    margin-left: 71vmin;
    min-width: 2vmin;
    max-width: 5vmin;
   
    font-size:3vmin;
    text-align: center;
    position: absolute;
  }
  
  .p11 {
    text-align: center;
    min-width: 40vmin;
    max-width: 40vmin;
    margin-top: 84vmin;
    margin-left: 2vmin;
    font-size:3vmin;
    position: absolute;
  }
  
  .p011 {
    margin-top: 70vmin;
    margin-left: 21vmin;
    min-width: 2vmin;
    max-width: 5vmin;
    font-size:3vmin;
    text-align: center;
    position: absolute;
  }
  
  .p12 {
    text-align: center;
    min-width: 40vmin;
    max-width: 40vmin;
    margin-top: 84vmin;
    margin-left: 47vmin;
    font-size:3vmin;
    position: absolute;
  }
  
  .p012 {
    margin-top: 70vmin;
    margin-left: 65vmin;
    min-width: 2vmin;
    max-width: 5vmin;
    font-size:3vmin;
    text-align: center;
    position: absolute;
  }
  }

  .native-input[type=date]::-webkit-calendar-picker-indicator {
   // background: url(https://cdn3.iconfinder.com/data/icons/linecons-free-vector-icons-pack/32/calendar-16.png) center/80% no-repeat;
 
   background-color: grey;
  }
  .native-input[type=time]::-webkit-calendar-picker-indicator {
   // background: url(https://cdn3.iconfinder.com/data/icons/linecons-free-vector-icons-pack/32/calendar-16.png) center/80% no-repeat;
   background-color: grey;
  }


/* For Firefox */
ion-input[type=date] {
  -moz-appearance: none; /* Remove default appearance */
}

ion-input[type=date]::-moz-calendar-picker-indicator {
  background-color: grey; /* Set background color */
}